import React from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import styles from "../styles/index.module.css"
import { Helmet } from "react-helmet"
import { Container } from "@material-ui/core"

export default function imprint() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Philipp Schulz Consulting</title>
        <meta name="robots" content="index, follow" />
        <link
          rel="icon"
          type="image/png"
          href="https://firebasestorage.googleapis.com/v0/b/ps-c-b11af.appspot.com/o/Favicon-PSc-32x32px.png?alt=media&token=af2df22c-7858-4b26-b434-3793e62de831"
          sizes="16x16"
        />
      </Helmet>
      <div>
        <Navbar />
        <Container maxWidth="md" className={styles.container}>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
              IMPRESSUM
            </span>
          </p>

          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              Name: Mag. Philipp Schulz&nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              Gewerbeberechtigt in Leonding, Austria
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              Unternehmensgegenstand: Unternehmensberatung einschließlich
              Unternehmensorganisation
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              Kontakt: office@ps-c.at
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              UID-Nr: ATU57325013
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              Mitglied der WKÖ, UBIT
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              Gewerbeordnung:
              <a href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10007517">
                www.ris.bka.gv.at
              </a>
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          ></p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              Gewerbebehörde: Bezirkshauptmannschaft Linz Land {<br />}
              {<br />}
              {<br />}
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              PORTRAITPHOTOGRAPHIE: Tom Mesic,{" "}
              <a href="https://www.tommesic.com/">www.tommesic.com</a>
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              PROGRAMMIERUNG: Felix Tatschl,{" "}
              <a href=" https://romantika5.com/"> GREENSOCKS</a>
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              CORPORATE UND WEB DESIGN:{" "}
              <a href=" http://www.sub-design.net/">
                sub. communication design
              </a>
            </span>
          </p>

          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              BACKGROUND IMAGE: gigi.nyc (Instagram)
            </span>
          </p>

          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              Verbraucher haben die Möglichkeit, Beschwerden an die
              Online-Streitbeilegungsplattform der EU zu richten:
              http://ec.europa.eu/odr. Sie können allfällige Beschwerde auch an
              die oben angegebene E-Mail-Adresse richten.
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              Dies Website hat die Zielsetzung der Information von potentiellen
              Kunden über das Leistungsspektrum der Firma und Informationen über
              aktuelle Lösungen und Entwicklungen im selben Kontext.
            </span>
          </p>
          <p>
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              <br />
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              lineHeight: "107%",
              fontSize: "15px",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span
              style={{
                fontSize: "19px",
                lineHeight: "107%",
                fontFamily: '"Open Sans",sans-serif',
              }}
            >
              &nbsp;
            </span>
          </p>
        </Container>
        <Footer />
      </div>
    </>
  )
}
